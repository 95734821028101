@use "./../../styles.scss";

$buttonMenuSelectedWidth: 100%;
$buttonMenuSelectedBorderRadius: 30px 0px 0px 30px;

.sidenav {
    background-color: styles.$main-color;
    flex-direction: column;
    overflow-x: hidden;
    align-items: end;
    position: fixed;
    display: flex;
    z-index: 1;
    height: 100%;
    width: 5vw;
    top: 0;
    left: 0;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding-left: 1vw;
    transition: align-items 10s cubic-bezier(0.82, 0.085, 0.395, 0.895);

    &:hover .menu-button--selected {
        $buttonMenuSelectedWidth: 85%;
        border-radius: 30px;
    }

    &:hover .menu-button__label {
        display: block;
    }
    &:hover .menu-button__icon {
        margin-left: 0.5vw;
        margin-right: 1vw;
        flex: none;
        display: flex;
    }

    &__logo {
        margin-bottom: 2vh;

        &-container {
            display: flex;
            margin-right: 0.6vw;
            margin-top: 6vh;
            transition: all 500ms ease 0s;

            & > img {
                width: 100%;
                height: 100%;
                transition: all 500ms ease 0s;
            }
        }
    }

    &:hover &__logo-container {
        display: flex;
        transform: translateX(3vw);
        margin-top: 6vh;
        transition: all 500ms ease 0s;
    }

    &:hover &__logo-container > img {
        height: 30%;
        width: 60%;
        transition: all 500ms ease 0s;
    }

    &:hover {
        align-items: center;
        padding: 0 1vw;
        transition: align-items 10s cubic-bezier(0.82, 0.085, 0.395, 0.895);
    }
}

.menu-button {
    @include styles.customFont($size: 16px, $line: 21px);
    background-color: transparent;
    height: 45px;
    width: 100%;
    border-radius: 30px;
    border-color: transparent;
    color: styles.$background-color;
    margin-top: 1%;
    margin-bottom: 0.7vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    cursor: pointer;

    &__icon {
        flex: auto;

        &-stroke {
            stroke: styles.$background-color;

            &--selected {
                stroke: styles.$main-color !important;
            }
        }

        &--filled {
            stroke: styles.$background-color;
            fill: styles.$background-color;
        }
    }

    &__label {
        @include styles.customFont($size: 16px, $line: 21px);
        text-align: left;
        display: none;
        text-wrap: nowrap;
    }

    &:hover {
        background-color: styles.$background-color;
        color: styles.$main-color;

        .menu-button__icon-stroke {
            stroke: styles.$main-color !important;
        }

        .menu-button__icon--filled {
            stroke: styles.$main-color;
            fill: styles.$main-color;
        }
    }

    &--selected {
        background-color: styles.$background-color;
        width: $buttonMenuSelectedWidth;
        border-radius: $buttonMenuSelectedBorderRadius;
        height: 45px;
        color: styles.$main-color;
        margin-top: 1%;
        margin-bottom: 0.7vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        border-color: transparent;
        outline: none;
    }
}

@media screen and (max-width: 1280px) {
    .menu-button__label {
        white-space: pre-wrap;
    }
}
