@use "./../../styles.scss";

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3vh;

    &__search {
        flex: 7;
        display: flex;
        height: 60px;
        width: 100%;

        &-input {
            @include styles.customFont($size: 15px, $line: 21px);
            box-sizing: border-box;
            width: 100%;
            border-radius: 10px;
            border-color: transparent;
            color: #797979;
            border: 2px solid #e8e8e8;
            background-image: url("../../assets/searchicon.png");
            background-size: 1.3%;
            background-position: 1% 50%;
            background-repeat: no-repeat;
            padding: 12px 20px 12px 40px;
        }
    }

    &__avatar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60px;
        margin-left: 1%;
        background-color: #f3f3f3;
        border-radius: 10px;
        align-items: center;
        width: 17rem;

        .sb-avatar {
            margin-inline: 1rem;
        }

        .sb-avatar__image {
            border-radius: 10px;
        }

        &-information {
            flex: 3;
            display: flex;
            flex-direction: column;

            &-name {
                font-family: Manrope;
                font-weight: 700;
                font-size: 14px;
                color: #000000;
            }

            &-job-title {
                font-family: Manrope;
                font-weight: 700;
                font-size: 14px;
                color: styles.$main-color;
            }
        }
    }
}

@media screen and (width: 1280px) {
    .header-container__search-input {
        background-size: 2.1%;
        background-position: 1.5% 50%;
    }
}
