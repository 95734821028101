// .form-header {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 50%;
//     height: 70%;
//     background-color: #ffffff;
//     border: 1px solid transparent;
//     box-shadow: 24;
//     padding: 2%;
//     border: 32px;
// }

.form-drawer {
    width: 20%;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    overflow-y: hidden;

    &__header {
        display: flex;
        background-color: #62098c;
        justify-content: end;
        padding: 10%;

        & > button {
            background-color: transparent;
            cursor: pointer;
            border: none;
            font-weight: 700;
        }

        &__title {
            display: flex;
            background-color: #62098c;
            color: #fff;
            height: 5%;
            align-items: flex-end;
            padding-left: 10%;
            padding-bottom: 5%;
            font-family: Manrope;
            font-weight: 700;
            font-size: 16px;
            line-height: 21.86px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding-top: 1.5rem;
        padding-inline: 8%;
        overflow-y: scroll;

        &__field-container {
            display: flex;
            margin-bottom: 1rem;
            flex-direction: column;
        }

        &__label {
            color: #505050;
            font-family: Manrope;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0.5rem;
        }

        &__input {
            border-radius: 3px;
            padding-inline: 0.7rem;
            border: 1px solid #e6e6e6;
            height: 2rem;
            font-family: Manrope;
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 500;

            &--disabled {
                background: #f5f5f5;
                color: #bfbfbf;
                cursor: default;
            }
        }

        &__pill {
            border: 1px solid #cdcdcd;
            border-radius: 24px;
            background-color: transparent;
            height: 40px;
            width: 50%;
            cursor: pointer;
            color: #505050;
            text-align: center;
            font-family: Manrope;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            &--disabled {
                background: #f5f5f5;
                color: #bfbfbf;
                text-align: center;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: inherit;
            }
        }

        &__footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-block: 1rem;
            padding-inline: 2rem;

            &__button {
                border: 0px solid transparent;
                background-color: transparent;
                height: 28px;
                width: 45%;
                cursor: pointer;
                font-family: Manrope;

                &--fill {
                    border: 1px solid #cdcdcd;
                    border-radius: 24px;
                    background-color: #62098c;
                    color: #fff;
                }
            }
        }
    }
}
