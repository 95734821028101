.title-input {
    font-family: Manrope;
    font-weight: 700;
    font-size: 12px;
}

.text-field {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    padding: 0;
    background-color: #ffffff;

    &--disabled {
        background-color: #e6e6e6;
    }
}
