/* 
    File created: 05/03/2024
    Authors: 
        Erik Josías González Lucas

    This file was created to standardising styles for modals components
*/
.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid transparent;
    box-shadow: 24;
    padding: 2%;
    border-radius: 32px;
    display: flex;
    flex-direction: column;

    &--medium {
        width: 50%;
        height: 70%;
    }

    &--big {
        width: 50%;
        height: 85%;
    }

    &__title {
        font-family: Manrope;
        font-weight: 600;
        background-color: #62098c;
        color: white;
        border-radius: 12px;
        height: 6%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 0.5rem;
        margin-bottom: 1rem;

        & > div {
            display: flex;
            align-items: center;
            height: 100%;

            & > h4 {
                font-size: 14px;
                width: 100%;
                text-wrap: nowrap;
                margin-right: 0.5rem;
            }

            & > p {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.4);
                font-size: 14px;
                padding-inline: 1.5rem;
                text-align: center;
                height: 65%;
                text-wrap: nowrap;
            }
        }
    }

    &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
        width: 100%;
        color: #797979;
        font-family: Manrope;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__search-input {
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        background: #fff;
        height: 3rem;
        padding-left: 1rem;
        color: #797979;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__circles {
        display: flex;
        align-items: center;

        & > h5 {
            margin-left: 1rem;
        }
    }

    &__circle {
        margin-right: 0.3rem;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 0.8rem;

        &--purple {
            background-color: #62098c;
        }

        &--cyan {
            background-color: #60b2ee;
        }

        &--green {
            background-color: #8cda5c;
        }

        &--yellow {
            background-color: #ffd80b;
        }

        &--blue {
            background-color: #0222c5;
        }

        &--red {
            background-color: #ce1010;
        }
    }

    &__body {
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 94%;

        & > h3 {
            font-family: Manrope;
            margin: 0;
            color: #797979;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 0.5rem;

            & > span {
                font-weight: 700;
            }
        }

        &__table {
            overflow: auto;
            scrollbar-width: thin;
            height: 100% !important;

            &__header {
                display: flex;
                color: #797979;
                font-family: Manrope;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-bottom: 2px solid #e3e3e3;
            }

            &__cell {
                &--big {
                    width: 40%;
                }

                &--medium {
                    width: 15%;
                }

                &--small {
                    width: 8%;
                }
            }

            &__row {
                display: flex;
                justify-content: start;
                color: #797979;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0 !important;
            }

            & > div {
                display: flex;
                align-items: center;
                margin-top: 1rem;

                h3 {
                    margin: 0;
                    width: fit-content;
                    text-wrap: nowrap;
                    color: #be98be;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-right: 1rem;
                }
            }

            &__line {
                height: 1px;
                width: 100%;
                background: #be98be;
            }

            &__options {
                display: flex;
                flex-direction: column;

                & > div {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    & > h4 {
                        color: #000;
                        font-family: Manrope;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        margin-right: 2rem;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;

            & > button {
                margin-inline: 1rem;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: 0;
                cursor: pointer;
            }

            &--cancel {
                color: #a1a1a1;
                background-color: transparent;
            }

            &--accept {
                border-radius: 1rem;
                padding-block: 0.4rem;
                padding-inline: 2rem;
                background: #62098c;
                color: white;
            }
        }
    }
}

.css-1yjjitx-MuiSwitch-track {
    opacity: 0.2 !important;
}

@media only screen and (min-height: 900px) {
    .custom-modal--big {
        height: 70%;
    }
}
