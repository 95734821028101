.upload-container {
    width: 97%;
    flex: 1;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    padding: 2%;
    padding-right: 1vw;
}

#upload-body {
    flex: 1;
    align-self: center;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
        color: #797979;
        text-align: center;
        font-family: Manrope;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 400;
    }

    & > button {
        background-color: #62098c;
        color: #ffffff;
        width: 18vw;
        height: 4vh;
        border-radius: 25px;
        border: 1px solid transparent;
        cursor: pointer;
        text-align: center;
        font-family: Manrope;
        font-size: 1vw;
        font-style: normal;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
    }
}

#modal-error {
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        height: 40vh;
        width: 26vw;
        border-radius: 10px;
        background-color: white;
        outline: none;
        display: flex;
        flex-direction: column;

        &-header {
            background-color: #62098c;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            flex: 2;
            color: #fff;
            font-family: "Manrope";
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            align-items: end;
            padding-bottom: 1vh;
            padding-left: 1.5vw;
        }

        &-body {
            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > h4 {
                color: #797979;
                text-align: center;
                font-family: "Manrope";
                font-size: 1.1vw;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-block: 1.5vh 3vh;
            }

            & > button {
                border-color: transparent;
                font-family: "Manrope";
                background: #62098c;
                border-radius: 25px;
                font-style: normal;
                font-weight: 700;
                cursor: pointer;
                font-size: 1vw;
                height: 3.5vh;
                color: #fff;
                outline: none;
                width: 6vw;
            }

            & > div {
                color: #797979;
                text-align: center;
                font-family: "Manrope";
                font-size: 1.3vw;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 1vh;
            }
        }
    }
}
