@use "../../styles";

.sectionTitle {
    @include styles.customFont($size: 18px, $line: 19px);
    text-align: left;
    color: #797979;
    margin-bottom: 1vh;
}

.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 2vh;
}

.monthContainer {
    display: flex;
    flex: 2;
    flex-direction: row;
}

.sectorContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.monthButtons {
    display: flex;
    align-self: center;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-color: #dfdfdf;
    border-width: 1.5px;
    border-radius: 25px;
    background-color: white;
    cursor: pointer;
    margin-inline: 0.5vw;
    color: #797979;
}

.month-dot {
    align-self: center;
    width: 25px;
    height: 10px;
    border-radius: 10px;
    background-color: #797979;
    margin-left: 1%;
    margin-right: 1%;
}

.monthLabel {
    @include styles.customFont($size: 24px, $line: 28px);
    color: #000000;
    text-align: left;
    margin-right: 1vw;
}

.sectorLabel {
    @include styles.customFont($size: 13px, $line: 14px);
    color: #505050;
    align-self: center;
    text-align: left;
}

.sectorSelect {
    height: 28px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 2px solid #e6e6e6;
    margin-left: 3%;
    width: 100%;
}

.buttonAction {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-radius: 27px;
    border-color: transparent;
    background-color: #f3f3f3;
    padding: 0px 1% 0px 1%;
    color: styles.$text-type-1;
    cursor: pointer;
    margin-right: 10px;
}

.buttonAction:hover {
    background-color: styles.$main-color;
    color: styles.$background-color;
}

.iconColor {
    stroke: styles.$text-type-1;
    fill: styles.$text-type-1;
}

.iconColorOptions {
    fill: styles.$text-type-1;
}

.buttonAction:hover .iconColor {
    stroke: styles.$background-color;
    fill: styles.$background-color;
}

.iconColorSelected {
    stroke: styles.$background-color;
    fill: styles.$background-color;
}

.buttonActionSelected {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-radius: 27px;
    border-color: transparent;
    background-color: styles.$main-color;
    padding: 0px 1% 0px 1%;
    color: styles.$background-color;
    cursor: pointer;
    margin-right: 10px;
}

.modalTitleContainer {
    @include styles.customFont($size: 16px, $line: 21px);
    background-color: styles.$main-color;
    color: styles.$background-color;
    border-radius: 12px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.modalTitleContainerDelete {
    @include styles.customFont($size: 16px, $line: 21px);
    background-color: styles.$main-color;
    color: styles.$background-color;
    border-radius: 12px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.modalTitle {
    flex: 1;
}
.actionBarContainer {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}

.actionButtons {
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}

.actionButtons:hover {
    background-color: #e1e1e1;
}

table {
    background-color: transparent !important;
}

.inputLabel {
    @include styles.customFont($size: 13px, $line: 18px);
    display: flex;
    align-items: center;
    color: #505050;
    margin-bottom: 3%;
}

.inputGroup {
    margin-bottom: 5%;
}

.inputAssign {
    width: 95%;
    height: 28px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
}

// Estilos Refactor

#modal-assistance {
    margin: 20vh;
    outline: none;
}

#modal-assistance-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid transparent;
    box-shadow: 24px;
    padding: 4.5vh 0 0 0;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    overflow: hidden;
}

#modal-assistance-information {
    height: 100%;
    margin-inline: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-title-container {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #62098c;
    padding-inline: 0.8vw;
    height: 5vh;
}

.modal-title {
    color: #fff;
    font-family: Manrope;
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}

.tag {
    height: 1.2vh;
    background: #62098c;
}
