@use "./../../styles.scss";

.buttonAction {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-radius: 27px;
    border-color: transparent;
    background-color: #f3f3f3;
    padding: 0px 1% 0px 1%;
    color: styles.$text-type-1;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: styles.$main-color;
        color: styles.$background-color;
        stroke: styles.$background-color;
    }
}

.specialButtonAction {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-radius: 27px;
    border-color: transparent;
    background-color: #f3f3f3;
    padding: 0px 1% 0px 1%;
    color: styles.$text-type-1;
    cursor: pointer;
    margin-right: 10px;
}

.buttonActionOptions {
    @include styles.customFont($size: 14px, $line: 18px, $weight: 400);
    border-color: transparent;
    background-color: transparent;
    color: styles.$text-type-2;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        font-weight: 600 !important;
        color: styles.$main-color;
    }
}

.iconColorSelected {
    stroke: styles.$background-color;
    fill: styles.$background-color;
}

.buttonActionSelected {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-radius: 27px;
    border-color: transparent;
    background-color: styles.$main-color;
    padding: 0px 1% 0px 1%;
    color: styles.$background-color;
    cursor: pointer;
    margin-right: 10px;
}
.modalTitleContainer {
    @include styles.customFont($size: 16px, $line: 21px);
    background-color: styles.$main-color;
    color: styles.$background-color;
    border-radius: 12px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 3%;
    padding-right: 1%;
}
.modalTitleContainerEdit {
    @include styles.customFont($size: 18px, $line: 21px);
    background-color: styles.$main-color;
    color: styles.$background-color;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8%;
    padding-right: 5%;
    padding-top: 8%;
}
.modalTitle {
    flex: 1;
}
.actionBarContainer {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.actionButtons {
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}
.actionButtons:hover {
    background-color: #e1e1e1;
}
table {
    background-color: transparent !important;
}
.inputLabel {
    @include styles.customFont($size: 13px, $line: 18px);
    display: flex;
    align-items: center;
    color: #505050;
    margin-bottom: 3%;
}
.inputGroup {
    margin-bottom: 5%;
}
.inputAssign {
    width: 95%;
    height: 28px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
}
.GeneralContainer {
    margin-left: 10%;
    margin-right: 2%;
}

.SimpleTittle {
    @include styles.customFont($size: 15px, $line: 18px);
    display: flex;
    color: #797979;
}

.SimpleParagraph {
    @include styles.customFont($size: 12px, $line: 18px);
    color: #a4a4a4;
    text-transform: uppercase;
    font-weight: 100;
    margin-bottom: 5%;
}

.CommentsText {
    @include styles.customFont($size: 12px, $line: 18px);
    color: #a4a4a4;
    text-transform: uppercase;
    font-weight: 100;
}

.SimpleIcon {
    stroke: styles.$main-color !important;
    padding-left: 30%;
    padding-top: 20%;
    background-color: transparent;
    border: 0px solid transparent;
}

.ImportantText {
    @include styles.customFont($size: 14px, $line: 2px);
    color: #797979;
    font-weight: 100;
}

.Paragraph {
    @include styles.customFont($size: 13px, $line: 18px);
    font-weight: 100;
    color: black;
}

.LittleText {
    @include styles.customFont($size: 12px, $line: 2px);
    color: #a4a4a4;
    font-weight: 100;
}

.BtnComments {
    @include styles.customFont($size: 12px, $line: 2px);
    align-content: center;
    padding-top: 5%;
    padding-bottom: 8%;
    padding-left: 27%;
}
.BtnComm {
    @include styles.customFont($size: 12px, $line: 2px);
    align-content: center;
    padding-left: 12%;
}
.CommentsInput {
    border-color: #a4a4a4;
}
