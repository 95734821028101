.principalTable {
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #f6f6f6;
    margin-top: 2%;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.wrapper > div:first-of-type,
.line + div {
    grid-column: 2;
}

.line {
    grid-column: 1 / -1;
    height: 1px;
    background: #dfdfdf;
    margin-bottom: 3vh;
}
