html,
body {
    margin: 0;
    padding: 0;
}

#login-background {
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-modal {
    height: 25rem;
    width: 45rem;
    background-color: white;
    border-radius: 40px;
    padding: 2.5rem 3rem;
    box-shadow: 0px 2px 19px -8px rgba(0, 0, 0, 0.69);
    -webkit-box-shadow: 0px 2px 19px -8px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 2px 19px -8px rgba(0, 0, 0, 0.69);
    display: flex;
    justify-content: space-between;

    svg {
        height: 100%;
    }
}

#login-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    img {
        height: 3rem;
        width: 11rem;
    }

    div h1 {
        color: #000;
        font-family: "Manrope";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        margin: 0;
    }

    div h3 {
        color: #000;
        font-family: Manrope;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 157.9%;
        margin-block: 0 1rem;
    }

    div Button {
        border-radius: 24.5px;
        background: var(--Morado_main, #62098c);
        padding-inline: 2rem;
        color: #fff;
        text-align: center;
        font-family: Manrope;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
    }
}
