@use "./../../styles.scss";

.rdp-caption_label {
	@include styles.customFont($size: 14px, $line: 15px);
	text-transform: capitalize !important;
	margin: 0 auto !important;
	color: styles.$main-color !important;
}

.rdp-head_cell {
	color: #797979 !important;
	@include styles.customFont($size: 0.75em, $line: 0.75em);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
	background-color: rgba(0, 0, 0, 0.04) !important;
}

.rdp {
	--rdp-cell-size: 40px;
	--rdp-accent-color: styles.$main-color;
	--rdp-background-color: #e7edff;
	--rdp-accent-color-dark: styles.$main-color;
	--rdp-background-color-dark: #180270;
	--rdp-outline: 2px solid var(--rdp-accent-color);
	--rdp-outline-selected: 3px solid var(--rdp-accent-color);
}

.labels-container {
	border-radius: 8px;
	background-color: #f2f4f5;
	padding: 0.2rem;
	display: inline-block;
	margin-top: 1rem;
}

.iconColorOptions {
	fill: styles.$text-type-1;
}

.label-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--Texto, #797979);
	text-align: center;
	font-family: Manrope;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	height: 2rem;
	width: 10rem;
	text-transform: none !important;
	border: none !important;
}

.laboral-day {
	--rdp-accent-color: #62098c !important;
}

.no-laboral-day {
	--rdp-accent-color: #b78db7 !important;
}

.active-option {
	color: styles.$main-color !important;

	&--no-laboral {
		color: #b78db7 !important;
	}
}

.disabled-option {
	& > div {
		display: none;
	}
}

.first-indicator {
	width: 10px;
	height: 10px;
	border-radius: 24px;
	margin-right: 0.5rem;
	background-color: styles.$main-color;
}

.second-indicator {
	@extend .first-indicator;
	background-color: #b78db7;
}

.label {
	@include styles.customFont($size: 14px, $line: 19px);
	color: #505050;
	margin-left: 1%;
}

.monthContainer {
	display: flex;
	flex: none;
	flex-direction: row;
}

.sectorContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: none;
}

.monthButtons {
	align-self: center;
	width: 25px;
	height: 25px;
	border-style: solid;
	border-color: #dfdfdf;
	border-width: 1px;
	border-radius: 25px;
	background-color: white;
	cursor: pointer;
}

.changeDocumentButton {
	@include styles.customFont($size: 14px, $line: 15px);
	border-color: transparent;
	background-color: transparent;
	color: styles.$text-type-1;
	cursor: pointer;
	text-align: right;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32rem;
}

.dot {
	align-self: center;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: #797979;
	margin-left: 1%;
	margin-right: 1%;
}

.monthLabel {
	@include styles.customFont($size: 24px, $line: 28px);
	color: #000000;
	text-align: left;
	text-wrap: nowrap;
	width: auto;
	margin-right: 1rem;
	display: flex;
	align-items: center;
}

.sectorLabel {
	@include styles.customFont($size: 13px, $line: 14px);
	color: #505050;
	align-self: center;
	text-align: left;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.sectorSelect {
	height: 28px;
	border-radius: 3px;
	background-color: #ffffff;
	border: 2px solid #e6e6e6;
	margin-left: 1rem;
	width: 70%;
	color: #505050;
	font-family: Manrope;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-inline: 0.5rem 0.7rem;
}

.categorySelect {
	height: 28px;
	border-radius: 3px;
	background-color: #ffffff;
	border: 2px solid #e6e6e6;
	margin-left: 1rem;
	width: 70%;
	color: #505050;
	font-family: Manrope;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-inline: 0.5rem 0.7rem;
	margin-right: 1rem;
}

.button-actualizar-calendars {
	@include styles.customFont($size: 14px, $line: 15px);
	border-radius: 27px;
	border-color: transparent;
	background-color: styles.$main-color;
	padding: 0px 1% 0px 1%;
	color: white;
	cursor: pointer;
	width: 40%;
	margin-left: 1%;
	height: 36px;
}

.modalBody {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40%;
	height: 50%;
	background-color: styles.$background-color;
	border: 1px solid transparent;
	box-shadow: 24;
	padding: 2%;
	border-radius: 32px;
	display: flex;
}

.css-sox5kk-MuiBackdrop-root {
	background-color: rgba(0, 0, 0, 0.25) !important;
}

.disabled-select .rdp-button {
	cursor: auto !important;
}
