.mainTable {
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #f6f6f6;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.mainTableContent {
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #f6f6f6;
    width: 99.1709169%;
    text-align: left;
    border-collapse: collapse;
}

.table-header {
    margin-top: 2%;
}
.th-first {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Manrope";
    color: #555555;
    border-top-left-radius: 20px;
    background-color: #f1f1f1;
}
.th-last {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Manrope";
    color: #555555;
    border-top-right-radius: 20px;
    background-color: #f1f1f1;
}
.th-middle {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Manrope";
    color: #555555;
    background-color: #f1f1f1;
}
td {
    color: black;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    font-family: "Manrope";
    width: fit-content;
    margin-left: 10px;
    margin-right: 10px;
}
th,
td {
    padding: 10px;
}
.td-content {
    padding: 0.75% 1%;
}
.actionButtons {
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}
.actionButtons:hover {
    background-color: #e1e1e1;
}
.textUppercase {
    text-transform: uppercase;
}

.textCategoria {
    font-weight: 700;
}
.selectProfesional {
    color: #797979;
    border: 1px solid #e8e8e8;
    width: 160px;
}

.actions-container {
    width: 5%;
    margin-right: 3%;

    &__wrapper {
        display: flex;

        & > div {
            flex: 1;
        }
    }
}

tr:hover {
    background-color: #f1f1f1;
}
