.modal-input-title {
    color: var(--dark_gray, #505050);
    font-family: Manrope;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5vh;
}

.modal-body {
    margin-inline: 2vw;
}
