@use "./../../styles.scss";

.ParagraphText {
    @include styles.customFont($size: 15px, $line: 18px);
    color: #797979;
    font-weight: 400;
    margin-bottom: 1%;
}

.FridayText {
    @include styles.customFont($size: 20px, $line: 20px);
    color: black;
    font-weight: 700;
    margin-bottom: 1%;
}

.ProffesionalBtn {
    @include styles.customFont($size: 14px, $line: 22px);
    height: 36px;
    border-color: transparent;
    background-color: transparent;
    color: styles.$text-type-1;
    cursor: pointer;
    margin-top: 5%;
    margin-left: 63%;
    margin-bottom: 2%;
}

.SearchInput {
    @include styles.customFont($size: 12px, $line: 20px);
    background-color: styles.$background-color;
    border: 1px solid #e8e8e8;
    height: 22px;
    width: 250px;
    margin-right: 30px;
    border-radius: 3px;
    background-image: url("../../assets/searchicon.png");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 10px 10px 10px 40px;
}
.MatutinoSelect {
    @include styles.customFont($size: 12px, $line: 20px);
    color: #797979;
    height: 44px;
    border: 1px solid #e8e8e8;
    width: 250px;
    border-radius: 3px;
    margin-right: 30px;
    text-transform: uppercase;
    padding: 12px 12px 12px 12px;
}
.TodosSelect {
    @include styles.customFont($size: 12px, $line: 20px);
    color: #797979;
    height: 44px;
    border: 1px solid #e8e8e8;
    width: 250px;
    border-radius: 3px;
    margin-right: 30px;
}
.AsignBtn {
    @include styles.customFont($size: 14px, $line: 20px);
    border-radius: 30px;
    border-color: transparent;
    background-color: #ac98d3;
    color: styles.$background-color;
    width: 105px;
    height: 40px;
}

.canAsignBtn {
    @include styles.customFont($size: 14px, $line: 20px);
    border-radius: 30px;
    border-color: transparent;
    color: #ffff;
    background-color: styles.$main-color;
    width: 105px;
    height: 40px;
}

.canAsignBtn:hover {
    background-color: styles.$secondary-color;
}

.TableTitle {
    @include styles.customFont($size: 14px, $line: 18px);
    font-weight: 700;
    color: #797979;
    text-align: left;
}
