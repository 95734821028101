// Common Colors
$main-color: #62098c;
$secondary-color: #9836c5;
$background-color: #ffffff;
$text-type-1: black;
$text-type-2: #6b6b6b;

@mixin customFont($size: 12px, $line: 20px, $weight: 700) {
    font-family: "Manrope" !important;
    font-weight: $weight !important;
    font-size: $size !important;
    line-height: $line !important;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
div {
    scrollbar-width: thin;
    scrollbar-color: #bdbdbd #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 14px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

/** Input **/
.custom-input {
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: $background-color;
    height: 2.5rem;
    padding-left: 1rem;
    color: #797979;
    font-family: Manrope;
    font-size: 14px;
    line-height: normal;
    font-style: normal;
    font-weight: 400;

    &:hover {
        border-color: $secondary-color;
    }

    &:focus-visible {
        outline: $main-color auto 1px;
    }
}

/** Buttons **/
.button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid transparent;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #e1e1e1;
    }
}

.plain-button {
    @include customFont($size: 14px, $line: 22px);
    height: 36px;
    border-color: transparent;
    background-color: transparent;
    color: $text-type-1;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;

    &--icon {
        fill: $text-type-1;
        width: 20px;
    }

    &--medium {
        width: 15rem;
    }

    &:hover {
        color: $main-color;

        .plain-button--icon {
            fill: $main-color;
        }
    }
}

.pill-button {
    @include customFont($size: 14px, $line: 15px);
    border-radius: 27px;
    border-color: transparent;
    background-color: $main-color;
    color: $background-color;
    cursor: pointer;
    width: 12rem;
    height: 2.5rem;

    &:hover {
        background-color: $secondary-color;
    }
}

/** CUSTOM MUI COMPONENTS **/

/* Autocomplete */
.custom-autocomplete {
    .MuiOutlinedInput-root {
        width: 17rem;
        &:hover fieldset {
            border-color: $main-color;
        }

        &.Mui-focused fieldset {
            border-color: $main-color;
        }
    }

    .MuiInputLabel-root {
        &.Mui-focused {
            color: $main-color;
        }
    }
}

/* ToggleButton */
.custom-togglebutton {
    .MuiToggleButton-root {
        &.Mui-selected {
            background-color: $background-color;
            border-radius: 8px;
            margin-inline: 0.1rem;
        }
    }
}
