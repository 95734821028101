.css-1d31tco-MuiPaper-root-MuiDrawer-paper {
    width: 20% !important;
}

.patient-activity {
    &__header {
        display: flex;
        background-color: #62098c;
        justify-content: end;
        padding: 5%;

        &-title {
            display: flex;
            background-color: #62098c;
            color: #ffffff;
            align-items: flex-end;
            padding-left: 10%;
            padding-bottom: 5%;
            font-family: Manrope;
            font-weight: 700;
            font-size: 22px;
            height: 5rem;
        }
    }

    &__body {
        padding: 6%;
        overflow-y: scroll;
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 4%;
        padding-bottom: 1rem;

        &-button {
            border: 1px solid #cdcdcd;
            border-radius: 24px;
            background-color: #62098c;
            height: 35px;
            cursor: pointer;
            color: #ffffff;
            width: 60%;
            margin: 3%;
            font-family: Manrope;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 1280px) {
    .css-1d31tco-MuiPaper-root-MuiDrawer-paper {
        width: 28% !important;
    }
}
